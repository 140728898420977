<script setup lang="ts">
import { RouterView } from "vue-router";
import LINESeedJP_OTF_Bd from "@/assets/fonts/LINESeedJP_OTF_Bd.woff2";
import LINESeedJP_OTF_Eb from "@/assets/fonts/LINESeedJP_OTF_Eb.woff2";
import FontFaceObserver from "fontfaceobserver";
import { ref } from "vue";
import SidebarContent from "./views/sidebar-content.vue";
import { computed } from "vue";
import router from "@/router";

// フォントのオブザーバーを作成
const font = new FontFaceObserver("LINESeedJPStd");

// フォントがロードされたら実行される
font.load().then(
  () => {
    document.documentElement.classList.add("fonts-loaded");
  },
  () => {
    console.error("LINESeedJPStd font could not be loaded after 3 seconds.");
  }
);

const style = document.createElement("style");
style.textContent = `
  @font-face {
    font-family: 'LINESeedJPStd';
    src: url('${LINESeedJP_OTF_Bd}') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap; /* ロード中はフォールバックフォントを使用 */
  }
  @font-face {
    font-family: 'LINESeedJPStd';
    src: url('${LINESeedJP_OTF_Eb}') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap; /* ロード中はフォールバックフォントを使用 */
  }
`;
document.head.append(style);
</script>

<script lang="ts">
const prev = ref<string | undefined>();
const hamburger = ref<"left" | "right" | "none" | null>(null);
const hamburgerClick = ref<() => void>(() => {});

export const setPrevLink = (value?: string) => {
  prev.value = value;
};

const path = computed(() => {
  return router.currentRoute.value.path;
});

const meta = computed(() => {
  return router.currentRoute.value.meta;
});

export const setHamburger = (value: "left" | "right" | "none", callback: () => void) => {
  hamburger.value = value;
  hamburgerClick.value = callback;
};

const isVisible = ref(true);
const sidebar_animation = ref<"" | "navigation-show" | "navigation-hide">("");
const content_animation = ref<"" | "content-expand" | "content-shrink">("");
const overlay_animation = ref<"" | "overlay-show" | "overlay-hide">("");

const setTransitionDuration = (duration: string) => {
  document.documentElement.style.setProperty("--transition-duration", duration);
};

const resetTransitionDuration = () => {
  setTimeout(() => {
    setTransitionDuration("0s");
  }, 300);
};

export const setVisible = (value: boolean) => {
  isVisible.value = value;

  if (!isVisible.value) {
    content_animation.value = "content-expand";
  }
};

export const setOpen = (value: boolean) => {
  setTransitionDuration("0.3s");

  sidebar_animation.value = value ? "navigation-show" : "navigation-hide";
  content_animation.value = value ? "content-shrink" : "content-expand";
  overlay_animation.value = value ? "overlay-show" : "overlay-hide";

  if (value) {
    setHamburger("none", () => {});
  } else {
    setHamburger("right", () => {});
  }

  resetTransitionDuration();
};

let prevWidth = 0;
const updateHamburgerState = () => {
  if (prevWidth >= 1024 && document.body.clientWidth < 1024) {
    setOpen(false);
  } else if (prevWidth < 1024 && document.body.clientWidth >= 1024) {
    setOpen(true);
  }
  prevWidth = document.body.clientWidth;
};

updateHamburgerState();

window.addEventListener("resize", () => {
  updateHamburgerState();
});
</script>

<template>
  <template v-if="path === '/login' || path === '/token-login' || path === '/register'">
    <RouterView class="flex flex-row bg-base text-mainText" />
  </template>
  <template v-else-if="meta.forPrint">
    <RouterView />
  </template>
  <div class="relative flex flex-row bg-base text-mainText" v-else>
    <RouterView ref="content" v-slot="{ Component }" class="content max-h-screen">
      <Component :is="Component" class="content max-h-screen" :content_animation="content_animation" :hamburger="hamburger" />
    </RouterView>
    <div class="overlay lg:hidden z-10 fixed w-screen h-screen" :class="overlay_animation" @click="setOpen(false)"></div>
    <div v-if="isVisible">
      <div class="fixed w-60 navigation min-h-screen z-20 right-0 h-full border-l border-outline" :class="sidebar_animation">
        <SidebarContent />
      </div>
      <div
        class="fixed z-20 right-1 top-1 w-8 h-8 cursor-pointer transition-all duration-150 bg-base border border-baseButton hover:bg-gray-200 rounded flex justify-center items-center"
        @click="setOpen(hamburger === 'none' ? false : true)"
      >
        <i :class="'fa-solid fa-bars text-mainText'" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
:root {
  --transition-duration: 0s;
}

.navigation {
  @media (min-width: 1024px) {
    width: 100%;
    transform: translateX(0px);
  }

  width: 100%;
  transform: translateX(15rem);
}

.overlay {
  display: none;
  background-color: transparent;
}

.navigation-show {
  width: 100%;
  will-change: transform;
  transform: translateX(0rem);
  transition-duration: var(--transition-duration);
  transition-timing-function: ease-out;
}

.navigation-hide {
  width: 100%;
  will-change: transform;
  transform: translateX(15rem);
  transition-duration: var(--transition-duration);
  transition-timing-function: ease-out;
}

.overlay-show {
  @media (max-width: 1023px) {
    animation-name: DarkOutAnime;
    animation-duration: var(--transition-duration);
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    display: block;
    background-color: transparent;
  }
}

.overlay-hide {
  @media (max-width: 1023px) {
    animation-name: DarkInAnime;
    animation-duration: var(--transition-duration);
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    display: none;
    background-color: transparent;
  }
}

.content {
  width: 100%;
}

.content-shrink {
  @media (min-width: 1024px) {
    padding-right: 15rem;
  }

  width: 100%;

  animation-name: ContentSizeChanged;
  transition-duration: var(--transition-duration);
  animation-duration: var(--transition-duration);
  will-change: width;
  transition-timing-function: ease-out;
}

.content-expand {
  width: 100%;
  will-change: width;
  transition-duration: var(--transition-duration);
  animation-duration: var(--transition-duration);
  transition-timing-function: ease-out;
  animation-name: ContentSizeChanged;
}

@keyframes ContentSizeChanged {
  0% {
    transition-duration: var(--transition-duration);
  }
  99% {
    transition-duration: var(--transition-duration);
  }
  100% {
    transition-duration: 0s;
  }
}

@keyframes DarkInAnime {
  0% {
    background-color: #00000080;
    display: block;
  }

  100% {
    background-color: transparent;
    display: none;
  }
}

@keyframes DarkOutAnime {
  0% {
    background-color: transparent;
    display: block;
  }

  100% {
    background-color: #00000080;
    display: block;
  }
}
</style>

<style lang="scss">
html,
body,
#app {
  height: 100%;
}

body {
  font-weight: normal;
  line-height: 1.6;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "pkna";
  color: #39445d;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: manipulation;
  overscroll-behavior-y: none;
}

.fonts-loaded body {
  font-family: "LINESeedJPStd", sans-serif;
}
.overflow-y-auto {
  overflow-y: auto;
  scrollbar-gutter: stable;
}
</style>

<style scoped lang="scss"></style>
