<script setup lang="ts">
import { computed, onMounted } from "vue";
import { Firebase } from "@/utils/firebase-utility";
import NavigationLink from "./navigation-link.vue";
import router from "@/router";
import BillPurserLogo from "@/assets/images/bill_purser_logo.svg";

const path = computed(() => {
  return router.currentRoute.value.path;
});

const logout = () => {
  Firebase.logout();
  location.reload();
};

onMounted(async () => {
  await Firebase.initialize();
});
</script>

<template>
  <div class="flex w-full h-full min-h-screen max-w-xs p-3 bg-white">
    <ul class="flex flex-col w-full">
      <li class="mt-12">
        <RouterLink to="/" class="flex flex-col justify-center items-center">
          <BillPurserLogo class="" />
        </RouterLink>
      </li>

      <hr class="h-0.5 my-4 bg-baseButton" />
      <div class="flex flex-col gap-1">
        <NavigationLink icon="house" link="/" :selected="path === '/'">ホーム</NavigationLink>
        <NavigationLink icon="building" link="/organizations" :selected="path === '/organizations'">組織管理</NavigationLink>
        <NavigationLink icon="user" link="/organization_users" :selected="path === '/organization_users'">ユーザ検索</NavigationLink>
      </div>

      <div class="mt-auto"></div>

      <div class="flex p-2 h-20 bg-baseButton rounded-md text-xs flex-col justify-between">
        <span class="flex items-center gap-2 text-mainText'"> 全体管理 </span>
        <div class="flex flex-col items-end">
          <span class="flex items-center gap-2 justify-center cursor-pointer" @click="logout">
            <div class="text-main">ログアウト</div>
            <i :class="'fa-solid fa-arrow-right-from-bracket text-main'" />
          </span>
        </div>
      </div>
    </ul>
  </div>
</template>

<style scoped lang="scss"></style>
