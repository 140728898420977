import { Firebase } from "../utils/firebase-utility";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),

  routes: [
    {
      path: "/",
      name: "ダッシュボード",
      component: () => import("../views/home/home-view.vue"),
      props: true,
    },
    {
      path: "/organizations",
      name: "組織一覧",
      component: () => import("../views/organization/list.vue"),
      props: true,
    },
    {
      path: "/organizations/:organizationId",
      name: "組織詳細",
      component: () => import("../views/organization/list.vue"),
      props: true,
    },
    {
      path: "/organization_users",
      name: "ユーザ検索",
      component: () => import("../views/organization/users.vue"),
      props: true,
    },
    {
      path: "/login",
      name: "ログイン",
      meta: { isLoginedOnly: false, isAnonymousOnly: true },
      component: () => import("../views/login/login-view.vue"),
      props: true,
    },
    {
      path: "/:path(.*)",
      redirect: { path: "/", replace: true },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const isLoginedOnly = to.matched.some((record) => record.meta.isLoginedOnly ?? true);
  const isAnonymousOnly = to.matched.some((record) => record.meta.isAnonymousOnly);

  // ログインページログイン状態を見る必要がなければ即時表示
  if (!isLoginedOnly && !isAnonymousOnly) {
    next();
    return;
  }

  const isLogined = await Firebase.logined;

  // ログイン済なのにログインページ表示しようとしてたらホームか、指定遷移先に遷移
  if (isLogined && isAnonymousOnly) {
    if (to.query.redirect) {
      next({ path: to.query.redirect as string });
    } else {
      next({ path: "/" });
    }
    return;
  }

  // ログイン必須ページなのにログインしていないなら、ログインページへ遷移
  if (isLoginedOnly && !isLogined) {
    next({ path: "/login", query: { redirect: to.fullPath } });
    return;
  }

  next();
});

router.beforeResolve((_to, _from, next) => {
  next();
});

export default router;
