<script setup lang="ts">
import router from "@/router";
import { computed } from "vue";
import { RouterLink } from "vue-router";

const props = defineProps<{
  text?: string;
  icon: AwesomeIconName;
  badge?: string;
  link: string;
  iconColor?: string;
  selected?: boolean | "auto";
}>();

const isSelected = computed(() => {
  if (props.selected === "auto") {
    return router.currentRoute.value.path.startsWith(props.link);
  } else {
    return props.selected;
  }
});
</script>

<template>
  <li class="my-px">
    <RouterLink :to="link" class="flex flex-row items-center h-10 px-3 rounded-lg text-mainText text-sm transition-all duration-150 hover:bg-mainLight" :class="isSelected ? 'bg-mainLight text-main' : ''">
      <span class="flex items-center justify-center text-lg  w-3" :class="isSelected ? 'text-main' : iconColor ?? 'text-mainText'">
        <i :class="'fa-solid fa-' + icon"></i>
      </span>
      <span class="ml-3">
        <slot>{{ text }}</slot>
      </span>
      <slot name="badge-wraper">
        <span
          v-if="badge !== undefined || $slots.badge !== undefined"
          class="flex items-center justify-center text-sm text-gray-500 font-semibold bg-gray-200 h-6 px-2 rounded-full ml-auto"
        >
          <slot name="badge">{{ badge }}</slot>
        </span>
      </slot>
    </RouterLink>
  </li>
</template>

<style scoped lang="scss"></style>
