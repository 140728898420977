import { createApp } from "vue";
import application from "./application.vue";
import router from "./router";
import "./tailwind.css";

const app = createApp(application);

app.use(router);

app.mount("#app");
