import { FirebaseApp, initializeApp } from "firebase/app";
import { getFirestore, Firestore, getDocs, query, collection, where, doc, DocumentReference, CollectionReference, FirestoreDataConverter, Timestamp, getDoc, onSnapshot } from "firebase/firestore";
import { getFunctions, Functions, httpsCallable, HttpsCallableResult } from "firebase/functions";
import { getAuth, Auth, User, signInWithPopup, GoogleAuthProvider, signOut, signInAnonymously, signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth";
import { DateTime } from "luxon";
import { ref } from "vue";
import { OrganizationPrv, OrganizationPrvPath, OrganizationPub, OrganizationPubPath } from "../../../functions/src/store/organization";
import { OrganizationUser, OrganizationUserPath } from "../../../functions/src/store/organization_user";

interface Config {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

class FirebaseUtility {
  private app: FirebaseApp;
  public firestore: Firestore;
  public _functions: Functions;
  public auth: Auth;
  public organizationPub = ref<OrganizationPub>();
  public organizationPrv = ref<OrganizationPrv>();
  public organizationUser = ref<OrganizationUser>();
  public organizationPubRef = ref<DocumentReference<OrganizationPub>>();
  public organizationPrvRef = ref<DocumentReference<OrganizationPrv>>();
  public organizationUserRef = ref<DocumentReference<OrganizationUser>>();
  public claims: {
    organizationId: string;
  };
  protected initializePromise: Promise<void>;
  public get user(): User | null {
    return this.auth.currentUser;
  }

  public get logined(): Promise<boolean> {
    return (async () => {
      await this.initialize();
      if (!this.auth.currentUser) {
        return false;
      }
      if (this.auth.currentUser.isAnonymous) {
        this.logout();
        return false;
      }
      const claims = (await this.auth.currentUser.getIdTokenResult()).claims;
      console.log(claims)
      if (!claims.admin) {
        this.logout();
        return false;
      }
      await this.loadData();
      return true;
    })();
  }

  public async reloadData() {
    this.auth = getAuth(this.app);
    await this.auth.currentUser?.getIdToken();
    await this.logined
    await this.loadData();
  }

  private async loadData() {
    if (!this.auth.currentUser) return;
  }

  constructor(protected config: Config) {
    this.app = initializeApp(config);
    this.firestore = getFirestore(this.app);
    this.auth = getAuth(this.app);
    this._functions = getFunctions(this.app, "asia-northeast1");

    this.initializePromise = this._initialize();
  }

  public async initialize(): Promise<void> {
    return this.initializePromise;
  }
  public async _initialize(): Promise<void> {
    return new Promise<void>((resolve) => {
      const unsubscribe = this.auth.onAuthStateChanged(async (user) => {
        unsubscribe();
        resolve();
      });
    });
  }

  // async getFSUser(): Promise<FS.User | undefined> {
  //   if (Firebase.user) {
  //     const docs = await getDocs(query(collection(Firebase.firestore, "users"), where("email", "array-contains", Firebase.user.email)));
  //     const doc = docs.docs[0];
  //     return doc.data() as FS.User;
  //   } else {
  //     return;
  //   }
  // }

  async func<RES>(name: string, data: any): Promise<HttpsCallableResult<RES>> {
    const callable = httpsCallable<string, RES>(this._functions, name);
    return await callable(JSON.stringify(data));
  }
  async loginEmailPassword(email: string, password: string) {
    await signInWithEmailAndPassword(this.auth, email, password);
  }
  async loginWithCustomToken(token: string) {
    await signInWithCustomToken(this.auth, token);
  }
  async loginWithGoogle() {
    await signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  async logout() {
    await signOut(this.auth);
  }
}

export const Firebase = new FirebaseUtility(FIREBASE_CONFIG);

export class BaseFirestoreData { }

export class BaseFirestorePath {
  name?: string;
  path: string;
  param: typeof PathParam;
  class: typeof BaseFirestoreData;
  isCollection?: boolean;
  isPrivate?: boolean;
}

export class PathParam {
  __dummy?: string;
}

const converter: FirestoreDataConverter<any> = {
  toFirestore: (data) => {
    toFirestoreConvert(data);
    return data;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    fromFirestoreConvert(data);
    return data;
  },
};

function toFirestoreConvert(obj: any) {
  for (const key in obj) {
    if (!obj[key]) {
      continue;
    } else if (obj[key] instanceof DateTime) {
      obj[key] = obj[key].toJSDate();
    } else if (typeof obj[key] === "object" && "toJSDate" in obj[key]) {
      obj[key] = obj[key].toJSDate();
    } else if (typeof obj[key] === "object") {
      toFirestoreConvert(obj[key]);
    } else if (typeof obj[key] === "function") {
      toFirestoreConvert(obj[key]);
    }
  }
}

function fromFirestoreConvert(obj: any) {
  for (const key in obj) {
    if (!obj[key]) {
      continue;
    } else if (obj[key] instanceof Timestamp) {
      obj[key] = DateTime.fromJSDate(obj[key].toDate());
    } else if (typeof obj[key] === "object") {
      fromFirestoreConvert(obj[key]);
    } else if (typeof obj[key] === "function") {
      fromFirestoreConvert(obj[key]);
    }
  }
}

export class BaseFirestore<TCol extends BaseFirestorePath, TDoc extends BaseFirestorePath> {
  constructor(public collectionPath: TCol, public documentPath: TDoc) { }
  getDocument(param: TDoc["param"]["prototype"]): DocumentReference<TDoc["class"]["prototype"]> {
    return doc(Firebase.firestore, getPathFromParam(this.documentPath.path, param)).withConverter(converter);
  }
  getCollection(param: TCol["param"]["prototype"]): CollectionReference<TCol["class"]["prototype"]> {
    return collection(Firebase.firestore, getPathFromParam(this.collectionPath.path, param)).withConverter(converter);
  }
}

export function getPathFromParam(path: string, param: Object) {
  for (const key in param) {
    const p = (param as any)[key];
    if (p) {
      path = path.replace(new RegExp("{" + key + "}", "g"), p);
    }
  }
  if (path.search(/[{}]/) >= 0) {
    console.error(path, param);
  }

  return path;
}
